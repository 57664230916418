<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 px-1"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="document-header px-3 pt-2 pb-1" style="flex: 0">
        <div class="conducted-box d-flex">
          <v-alert
              dense
              text
              class="mb-0 py-1"
              :color="conducted ? 'success' : 'secondary'"
              :type="conducted ? 'success' : 'warning'"
              style="flex: 1"
          >
            {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
          </v-alert>
        </div>
        <div class="document-header-row px-0 pt-2 pb-2">
          <div style="flex: 0 0 170px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required id="date"/>
          </div>
          <div style="flex: 0 0 110px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 270px; margin-right: 16px">
            <select_input
                v-model="person_id"
                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                :computed-getter="'get_personal_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Працівник"
                :show-select-on-focus="false"
                :required="true"
                :select-button-object="{
                          name: 'PERSONAL_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список працівників',
                          element_title: 'Працівник',
                          show: true
                        }"
            />
          </div>
        </div>
        <div class="document-header-row px-0 pt-1 pb-2">
          <div style="flex: 0 0 170px; margin-right: 16px">
            <date_input v-model="release_date" label="Дата звільнення: " :time="false"/>
          </div>
          <div style="flex: 0 0 396px; margin-right: 16px">
            <select_input
                v-model="release_reason"
                :computed-action="'SELECT_RELEASE_REASON_LIST_ELEMENTS'"
                :computed-getter="'get_release_reason_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Підстава звільнення"
                :show-select-on-focus="false"
                :required="true"
                :select-button-object="{
                          name: 'RELEASE_REASON_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список підстав звільнення',
                          element_title: 'Підстава звільнення',
                          show: true
                        }"
            />
          </div>
        </div>
        <div class="document-header-row px-0 pt-1 pb-2">
          <div style="flex: 0 0 582px; margin-right: 16px">
            <text_area_input v-model="comments" label="Коментар: " :required="false"/>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
             :loading="getModalLoading"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
             :loading="getModalLoading"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS,
  UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS,
  REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS,
  CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS
} from "@/store/actions/accounting/list";

import {QUESTION_SHOW} from "@/store/actions/question";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";
import releaseWorkAPI from "@/utils/axios/accounting/documents/release_work";
import {ALERT_SHOW} from "@/store/actions/alert";

const modalDeleteId = 'release_work_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_ReleaseWork",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    date_input: () => import("@/components/accounting/input/document_date_input.vue"),
    string_input: () => import("@/components/accounting/input/document_string_input.vue"),
    text_area_input: () => import("@/components/accounting/input/document_text_area_input.vue")
  },
  data() {
    return {
      document_state: {
        ready: false,
        header_ready: false,
        show: false,
        errors: []
      },
      person_id: this.item.person_id,
      release_date: this.item.release_date,
      release_reason: this.item.release_reason,
      comments: this.item.comments,
    }
  },
  methods: {
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'release_date': {id: 'release_date', text: 'Не заповнена дата звільнення'},
        'release_reason': {id: 'release_reason', text: 'Не заповнена підстава звільнення'},
      }
      const document_table = {
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(document_table, 'Таблиця', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },
    fetch_data_by_id() {
      if (this.isNew) return

      releaseWorkAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.date = data.date
            this.number = data.number
            this.person_id = data.person_id
            this.release_date = data.release_date
            this.release_reason = data.release_reason
            this.comments = data.comments
          })
    },
    clearPersonData(payload) {
      let local_data = payload || {}

      this.date = local_data.date || null
      this.number = local_data.number || null
      this.person_id = local_data.person_id || null
      this.release_date = local_data.release_date || null
      this.release_reason = local_data.release_reason || null
      this.comments = local_data.comments || null
      this.itemId = payload.id || null
    },

    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_RELEASE_WORK_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа звільнення працівника`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        person_id: this.person_id,
        release_date: this.release_date,
        release_reason: this.release_reason,
        comments: this.comments
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_RELEASE_WORK_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_RELEASE_WORK_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_RELEASE_WORK_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
